.footer-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.footer-main:empty {
  display: none;
}
