.tracking-container {
  padding: 0 30px 100px 30px;
}

.tracking-main-box {
  max-width: 800px;
  margin: auto;
  border-radius: 10px;
  border: 1px solid;
  overflow: hidden;
}

.provider-tracking-number-container {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid;
  justify-content: space-between;
}

.provider-tracking-number {
  margin-left: 20px;
}

.provider-name {
  font-weight: 600;
  color: #424242;
  font-size: 14px;
}

.tracking-number {
  margin-top: 2px;
  font-weight: bold;
  color: #124fd6;
  font-size: 14px;
}

.provider-logo {
  height: 48px;
  border-radius: 5px;
}

.estimated-delivery-time {
  background-color: #8fc95c30;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #424242;
  text-align: center;
  border-radius: 5px;
}

.btn-view {
  display: flex;
  padding: 3.5px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.btn-view-text {
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #424242;
}

.tracking_widget_tracking_events_section {
  padding: 20px 20px 0;
  overflow: hidden;
  border-bottom: 1px solid;
}

.heading_shipment_status {
  font-weight: 600;
  color: #0d8043;
  font-size: 24px;
  margin: 0;
  z-index: 1;
  position: relative;
}

.tracking_widget_tracking_events_section .tracking_detail {
  position: relative;
  margin-left: 50px;
  padding-bottom: 20px;
  margin-top: -1px;
  font-size: 14px;
}

.tracking_widget_tracking_events_section .tracking_detail::before {
  content: " ";
  background: #c1c1c1;
  display: inline-block;
  position: absolute;
  left: -37px;
  width: 9px;
  height: 100%;
  z-index: 0;
  top: 0;
}

.date_and_button:empty {
  display: none;
}

.date_and_button {
  display: flex;
  justify-content: space-between;
  padding: "20px 20px 0 20px";
}

.tracking_widget_tracking_events_section .tracking_detail {
  position: relative;
  margin-left: 50px;
  padding-bottom: 20px;
  margin-top: -1px;
  font-size: 14px;
}

.tracking_widget_tracking_events_section .shipped.tracking_detail::before {
  background: #0d8043;
}

.heading_shipment_status:before {
  content: "";
  display: inline-block;
  height: 36px;
  width: 36px;
  vertical-align: middle;
  margin-right: 12px;
  font-size: 20px;
  background-image: url(./../../Assets/Icons/shipped-color.png);
  background-size: cover;
  border-radius: 50%;
}

.origin_details_title {
  margin: 15px 0;
  padding: 0;
  font-size: 18px;
}

/* Delivered */
.heading_shipment_status.delivered {
  color: #4caf50;
}
.tracking_widget_tracking_events_section .tracking_detail.delivered::before {
  background: #4caf50;
}
.heading_shipment_status.delivered:before {
  background-image: url(./../../Assets/Icons/delivered.png);
}
/* In Transit */
.heading_shipment_status.in_transit {
  color: #05a9f4;
}
.tracking_widget_tracking_events_section .tracking_detail.in_transit::before {
  background: #05a9f4;
}
.heading_shipment_status.in_transit:before {
  background-image: url(./../../Assets/Icons/in-transit.png);
}
/* Pre Transit */
.heading_shipment_status.pre_transit {
  color: #673ab7;
}
.tracking_widget_tracking_events_section .tracking_detail.pre_transit::before {
  background: #673ab7;
}
.heading_shipment_status.pre_transit:before {
  background-image: url(./../../Assets/Icons/pre-transit.png);
}
/* Out for Delivery */
.heading_shipment_status.out_for_delivery {
  color: #8fc95c;
}
.tracking_widget_tracking_events_section
  .tracking_detail.out_for_delivery::before {
  background: #8fc95c;
}
.heading_shipment_status.out_for_delivery:before {
  background-image: url(./../../Assets/Icons/out-for-delivery.png);
}
/* Available for Pickup */
.heading_shipment_status.available_for_pickup {
  color: #ff9800;
}
.tracking_widget_tracking_events_section
  .tracking_detail.available_for_pickup::before {
  background: #ff9800;
}
.heading_shipment_status.available_for_pickup:before {
  background-image: url(./../../Assets/Icons/available-for-pickup.png);
}
/* On Hold */
.heading_shipment_status.on_hold {
  color: #ffdd00;
}
.tracking_widget_tracking_events_section .tracking_detail.on_hold::before {
  background: #ffdd00;
}
.heading_shipment_status.on_hold:before {
  background-image: url(./../../Assets/Icons/on-hold.png);
}
/* Return To Sender */
.heading_shipment_status.return_to_sender {
  color: #e91e63;
}
.tracking_widget_tracking_events_section
  .tracking_detail.return_to_sender::before {
  background: #e91e63;
}
.heading_shipment_status.return_to_sender:before {
  background-image: url(./../../Assets/Icons/return-to-sender.png);
}
/* Exception, Failed Attempt(failure) */
.heading_shipment_status.exception,
.heading_shipment_status.failure {
  color: #cd2128;
}
.tracking_widget_tracking_events_section .tracking_detail.exception::before,
.tracking_widget_tracking_events_section .tracking_detail.failure::before {
  background: #cd2128;
}
.heading_shipment_status.exception:before,
.heading_shipment_status.failure:before {
  background-image: url(./../../Assets/Icons/failure.png);
}

.content-enter {
  opacity: 0;
  height: 0;
}

.content-enter-active {
  opacity: 1;
  height: auto;
  transition: opacity 1500ms, height 3000ms;
}

.content-exit {
  opacity: 1;
}

.content-exit-active {
  opacity: 0;
  height: 0;
  transition: opacity 1500ms, height 3000ms;
}

.enhanced_journey {
  overflow: hidden;
  transition: height 0.5s ease;
}

.tracking_detail_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
}

span.est-delivery-date {
  background: #4caf5080;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 18px;
  display: inline-block;
  height: fit-content;
}

span.est-delivery-date.pre_transit {
  background: #673ab720;
}

span.est-delivery-date.in_transit {
  background: #05a9f420;
}

span.est-delivery-date.on_hold {
  background: #ffdd0020;
}

span.est-delivery-date.out_for_delivery {
  background: #8fc95c20;
}

span.est-delivery-date.available_for_pickup {
  background: #ff980020;
}

span.est-delivery-date.return_to_sender {
  background: #e91e6320;
}

span.est-delivery-date.exception,
span.est-delivery-date.failure {
  background: #cd212820;
}

.tracking_detail_section:empty {
  display: none;
}

/* RTL */
.translated-rtl .tracking-container {
  direction: rtl;
}

.translated-rtl .heading_shipment_status:before {
  margin-left: 12px;
  margin-right: 0;
}

.translated-rtl .tracking_widget_tracking_events_section .tracking_detail {
  margin-left: 0;
  margin-right: 50px;
}

.translated-rtl
  .tracking_widget_tracking_events_section
  .tracking_detail::before {
  right: -37px;
}

.translated-rtl .go_to_carrier_website_button {
  margin-left: 0 !important;
}

.translated-rtl .date_and_button_section {
  float: left !important;
}
