.branding-logo {
  height: 18px;
  margin: 0 10px;
  margin-top: 6px;
}

.branding-main {
  display: flex;
  justify-content: center;
  width: calc(100% - 60px);
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
}

.branding-text {
  color: #3c4758;
}
