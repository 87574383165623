body {
  background-color: #fafafa;
  top: 0 !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 1px !important;
}

div#\:0\.targetLanguage + * {
  display: none !important;
}

iframe#\:1\.container {
  display: none !important;
}

.goog-te-spinner-pos {
  display: none !important;
}

wordtune-suggestions-container-block {
  display: none;
}

:root {
  --toastify-color-success: #09d3ac !important;
}