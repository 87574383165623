.title-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.title-round {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: aqua;
}

.title-text {
  font-size: 35px;
  font-weight: bold;
  margin: 0 30px;
}

@media screen and (max-width: 550px) {
  .title-main {
    padding: 30px;
  }
  .title-text {
    font-size: 30px;
  }
}
