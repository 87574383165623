/* Google Component CSS */
.preloader {
  position: relative;
  height: 500px;
}

.preloader img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 1px !important;
}

div#\:0\.targetLanguage + * {
  display: none;
}

iframe#\:1\.container {
  display: none;
}

select {
  width: 100%;
  padding: 0 5px 0 30px;
  height: 34px;
  color: #39464e;
  box-shadow: 0 0 0 1px #e9e9e9;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}

/* Header CSS */
.header-main {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 1001;
}

.header-main a {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 550px) {
  .header-main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-logo {
    margin-bottom: 20px;
  }
}

.header-logo {
  max-height: 40px;
  max-width: 200px;
}

.globel_icon {
  height: 16px;
  width: 16px;
  position: absolute;
  z-index: 1;
  padding: 13px 0 0 10px;
}
