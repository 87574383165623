.form-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-input {
  width: 500px;
  height: 150px;
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
}

.button {
  font-weight: 600;
  width: 500px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 0px 80px 0;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid;
}

.button:hover {
  background-color: white !important;
}

svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

@media screen and (max-width: 550px) {
  .button {
    width: 100%;
  }
  .text-input {
    width: 100%;
  }
  .form-main {
    padding: 20px;
  }
}
